import { Button } from "@material-ui/core";
import styled from "styled-components";

const StdButton = styled(Button)`
margin-right: 10px;
  &.MuiButtonBase-root {
    color: rgb(217, 239, 255);
  }
`;

export default StdButton;
