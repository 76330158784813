import styled from "styled-components";

export const ModalWrapper = styled.div`
  padding: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  overflow: scroll;
  background: var(--white-color)
`