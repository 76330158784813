import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
export const AddButton = (props) => {
  return (
    <IconButton {...props} color="primary" component="span">
      <AddIcon style={{fontWeight: "bold"}}/>
    </IconButton>
  );
};


export default AddButton
