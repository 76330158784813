import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";

export const ObjectSelect = (props) => {
  const { className, label, data, valueKey, valueLabel, ...restProps } = props;
  return (
    <FormControl className={className}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        {...restProps}
      >
        {data.map((val, i) => (
          <MenuItem key={i} value={val[valueKey]}>
            {val[valueLabel]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default ObjectSelect;
