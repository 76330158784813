export default class Repository {
  constructor(route, axios) {
    this.req = axios
    this.route = route
  }

  async get(id){
    const {data} = await this.req.get(`${this.route}/${id}`)
    // if(!data.success) throw new Error(data.error);
    return data
  }

  async list(){
    const {data} = await this.req.get(`${this.route}`)
    // if(!data.success) throw new Error(data.error);
    return data
  }

  async create(obj){
    const {data} = await this.req.post(`${this.route}`, obj)
    // if(!data.success) throw new Error(data.error);
    return true
  }

  async update(obj){
    const {data} = await this.req.put(`${this.route}/${obj.id}`, obj)
    // if(!data.success) throw new Error(data.error);
    return true
  }

  async delete(id){
    const {data} = await this.req.delete(`${this.route}/${id}`)
    return true
  }

}