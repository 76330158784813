import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";

import PanelTable from "../../../common/tables/panel-table/PanelTable";
import {
  PanelContentWrapper,
  TopButtonsWrapper,
  GroupWrapper,
  Group,
  Flex,
  Card,
} from "./panelContent.styled.js";
import DataModal from "../../../common/modal/data-modal/DataModal";
import AddButton from "../../../common/buttons/AddButton";
import { DataForm } from "../../../forms/DataForm";
import { flatten } from "../../../common/common.function";

import store from "../../../../store/Store";
import { DataTable } from "../../../common/tables/DataTable";
import { ShowButton } from "../../../common/buttons/ShowButton";
import { EditButton } from "../../../common/buttons/EditButton";
import { DeleteButton } from "../../../common/buttons/DeleteButton";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import Api from "../../../../api/Api";

function PanelContent(props) {
  const DetailsTable = props.detailsTable;
  const {
    entity,
    columns,
    relations,
    form,
    isDeleteable,
    editAccess,
    dataToShowInDetailedView,
    groupedBy,
  } = props;
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [entityData, setEntityData] = useState([]);
  const [editAccessIds, setEditAccessIds] = useState(null);
  const [groups, setGroups] = useState([]);
  const [currentGroup, setCurrentGroup] = useState([]);
  const [currentRoute, setCurrentRoute] = useState(location.pathname);

  const onShow = (id) => changeRoute(`/${entity}/` + id);
  const onEdit = (id) => changeRoute(`/${entity}/edit/` + id);
  const onCreate = () => changeRoute(`/${entity}/add/`);
  const onDelete = (id) => {
    if (window.confirm("Czy na 100% chcesz to usunac?")) {
      Api[entity].delete(id).then(() => loadData());
    }
  };
  const handleModalClose = () => changeRoute(`/${entity}`);

  const changeRoute = (route) => {
    history.push(route);
    setCurrentRoute(route);
  };
  const loadData = async () => {
    let dt = (await store.getData(`${entity}`)).map((row) => flatten(row) );
    
  
    if (groupedBy && dt.length > 0) dt = groupBy(dt, groupedBy);
    setEntityData(dt);
    if (editAccess) {
      setEditAccessIds(await store.getEditAccessIds(`${entity}`));
    }
  };
  const getIdFromLocationPath = (locationPath) => {
    return locationPath.split(`/`).pop();
  };
  const handleSubmit = async () => {
    handleModalClose();
    setLoading(true);
    loadData().then(() => {
      setLoading(false);
    });
  };

  const groupBy = (dataToGroup, key) => {
    let grouped = {};
    let groupsFromData = [];

    dataToGroup.forEach((d) => {
      if (!grouped[d[key]]) grouped[d[key]] = [];
      if(!grouped[d[key]].find(obj => obj.id === d.id))grouped[d[key]].push(d);
      if (groupsFromData.indexOf(d[key]) === -1) groupsFromData.push(d[key]);
    });
    setGroups(groupsFromData);
    setCurrentGroup(groupsFromData[0]);
    return grouped;
  };

  const onGroupChange = (group) => {
    setCurrentGroup(group);
  };

  useEffect(async () => {
    setLoading(true);
    loadData().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(async () => {}, [loading]);

  function isShowEditButton(id) {
    if (!editAccessIds) return true;
    return editAccessIds.indexOf(id) > -1;
  }

  const onClick = (callbackFunction) => {
    if (callbackFunction) callbackFunction(this);
  };

  const handleChange = (event) => {};

  return (
    <>
      <PanelContentWrapper>
        {!!groupedBy && !!groups && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <FormControl style={{ width: "300px", margin: "25px" }}>
              <InputLabel id="demo-simple-select-label">Kategoria</InputLabel>
              <Select
                value={currentGroup}
                onChange={handleChange}
                label="Kategoria"
              >
                {groups.map((group, i) => (
                  <MenuItem
                    value={group}
                    onClick={() => onGroupChange(group)}
                    key={i}
                  >
                    {group}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}

        {/* <TopButtonsWrapper>
          <AddButton onClick={onCreate} />
        </TopButtonsWrapper> */}

        {/* {console.log(entityData)} */}
        {!!editAccess == !!editAccessIds && entityData && !loading ? (
          <Flex>
            {entityData[currentGroup]
              ? entityData[currentGroup].map((data) => {
                  return (
                    <Card>
                      <div>
                        <h4 style={{ textAlign: "center" }}>
                          {Object.values(data)[1]}{" "}
                        </h4>
                      </div>
                      <div style={{ margin: "10px" }}>
                        <p>
                          <strong>login:</strong> {Object.values(data)[2]}
                        </p>
                        <p>
                          <strong>password:</strong> {Object.values(data)[3]}
                        </p>
                      </div>
                      <div style={{ display: "flex", float: "right" }}>
                        <ShowButton
                          onClick={() =>
                            onClick(onShow(Object.values(data)[0]))
                          }
                        />
                        {isShowEditButton(Object.values(data)[0]) && (
                          <EditButton
                            onClick={() =>
                              onClick(onEdit(Object.values(data)[0]))
                            }
                          />
                        )}
                        {isShowEditButton(Object.values(data)[0]) && isDeleteable &&(
                          <DeleteButton
                            onClick={() => onDelete(Object.values(data)[0])}
                          />
                        )}
                      </div>
                    </Card>
                  );
                })
              : entityData.map((data) => {
                  return (
                    <Card>
                      <div>
                        <h4 style={{ textAlign: "center" }}>
                          {Object.values(data)[1]}{" "}
                        </h4>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <ShowButton
                          onClick={() =>
                            onClick(onShow(Object.values(data)[0]))
                          }
                        />
                        {isShowEditButton(Object.values(data)[0]) && (
                          <EditButton
                            onClick={() =>
                              onClick(onEdit(Object.values(data)[0]))
                            }
                          />
                        )}
                        {isShowEditButton(Object.values(data)[0]) && (
                          <DeleteButton
                            onClick={() => onDelete(Object.values(data)[0])}
                          />
                        )}
                      </div>
                    </Card>
                  );
                })}
          </Flex>
        ) : (
          <Flex>
            <CircularProgress />
          </Flex>
        )}

        {/* <PanelTable
            onShow={onShow}
            rows={entityData[currentGroup] ? entityData[currentGroup] : entityData}
            columns={columns}
            onDelete={onDelete}
            onEdit={onEdit}
            isDeleteable={isDeleteable}
            editAccessIds={editAccessIds}
          /> */}

        <TopButtonsWrapper>
          <AddButton onClick={onCreate} style={{ color: "white" }} />
        </TopButtonsWrapper>

        <BrowserRouter key={currentRoute}>
          <Switch>
            <Route path={`/${entity}/edit/:id`}>
              <DataModal
                open={currentRoute.includes(`/${entity}/edit/`)}
                onClose={handleModalClose}
              >
                <DataForm
                  form={form}
                  onSubmit={handleSubmit}
                  entityId={getIdFromLocationPath(currentRoute)}
                  entity={`${entity}`}
                  relations={relations}
                />
              </DataModal>
            </Route>
            <Route path={`/${entity}/add`}>
              <DataModal
                open={currentRoute.includes(`/${entity}/add`)}
                onClose={handleModalClose}
              >
                <DataForm
                  form={form}
                  onSubmit={handleSubmit}
                  entity={`${entity}`}
                  relations={relations}
                />
              </DataModal>
            </Route>

            <Route path={`/${entity}/`}>
              <DataModal
                open={currentRoute.includes(`/${entity}/`)}
                onClose={handleModalClose}
              >
                <DataTable
                  entity={`${entity}`}
                  relations={relations}
                  entityId={getIdFromLocationPath(currentRoute)}
                  toShow={dataToShowInDetailedView}
                  detailsTable={DetailsTable}
                />
              </DataModal>
            </Route>
          </Switch>
        </BrowserRouter>
      </PanelContentWrapper>
    </>
  );
}

export default PanelContent;
