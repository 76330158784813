import {
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { flatten } from "../../common.function";

export const EntityTable = (props) => {
  const { data, toShow } = props;

  const [tableData, setTableData] = useState(null);

  useEffect( () => {
    console.log(data);
     setTableData(flatten(data));
  }, []);

  return (
    tableData && (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Pole</TableCell>
              <TableCell align="right">Wartość</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {toShow.map((key, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {key}
                </TableCell>
                <TableCell align="right">{tableData[key]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  );
};
