import React from "react";
import PanelContent from "../common/panel-content/PanelContent";
import { PersonalAccessForm } from "../../forms/personalAccess-form/PersonalAccessForm";
import { EntityTable } from "../../common/tables/entity-table/EntityTable";

function PersonalAccess() {
  return (
    <>
      <PanelContent
        entity="personalAccess"
        relations={["user"]}
        columns={["id", "baseAccess.name"]}
        isDeleteable={true}
        form={PersonalAccessForm}
        dataToShowInDetailedView={["baseAccess.name", "baseAccess.login", "baseAccess.password","baseAccess.link", "baseAccess.description", "id"]}
        detailsTable={EntityTable}
        groupedBy="user.email"
      ></PanelContent>
    </>
  );
}

export default PersonalAccess;
