import React from "react";
import PanelContent from "../common/panel-content/PanelContent";
import { EntityTable } from "../../common/tables/entity-table/EntityTable";
import { AccessForm } from "../../forms/access-form/AccessForm";
import { PersonalAccessForm } from "../../forms/personalAccess-form/PersonalAccessForm";

function UserAccess() {
  return (
    <>
       <PanelContent
        entity="access"
        relations={["user", "category"]}
        columns={["id", "baseAccess.name","baseAccess.login", "baseAccess.password" ]}
        isDeleteable={false}
        editAccess={true}
        form={AccessForm}
        dataToShowInDetailedView={["baseAccess.name", "category.name", "baseAccess.login", "baseAccess.password","baseAccess.link", "baseAccess.description", "id"]}
        detailsTable={EntityTable}
        groupedBy="user.email"
      ></PanelContent>
    </>
  );
}

export default UserAccess;
