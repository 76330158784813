import React from "react";

import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";

import { PanelWrapper, TitleDivider } from "./aside.styled.js";
import { Person, VpnKey, PersonPinCircle, Label } from "@material-ui/icons";

import store from "../../../store/Store";

function Aside(props) {
  return (
    <PanelWrapper>
      <List className={"active"}>
        <Link to="/access">
          <ListItem button>
            <ListItemIcon>
              <VpnKey style={{color: "#025072"}}/>
            </ListItemIcon>
            <ListItemText primary={"Access"} style={{color: "#025072", fontWeight: "bold"}}  />
          </ListItem>
        </Link>
        <Link to="/personalAccess">
          <ListItem button>
            <ListItemIcon>
              <PersonPinCircle style={{color: "#025072"}} />
            </ListItemIcon>
            <ListItemText primary={"Personal access"} style={{color: "#025072", fontWeight: "bold"}}  />
          </ListItem>
        </Link>
      </List>
      {store.hasRoleOrBetter("manager") && (
        <>
          <TitleDivider dividerTitle="Managment" />
          <List style={{padding: "20px 0"}}>
            <Link to="/category" >
              <ListItem button>
                <ListItemIcon>
                  <Label style={{color: "#025072"}}/>
                </ListItemIcon>
                <ListItemText primary={"Categories"} style={{color: "#025072", fontWeight: "bold"}} />
              </ListItem>
            </Link>
            <Link to="/userAccess">
              <ListItem button>
                <ListItemIcon>
                  <Person style={{color: "#025072"}}/>
                </ListItemIcon>
                <ListItemText primary={"Users Accesses"} style={{color: "#025072", fontWeight: "bold"}} />
              </ListItem>
            </Link>
          </List>
        </>
      )}
      {store.hasRoleOrBetter("admin") && (
        <>
          <TitleDivider dividerTitle="Administration" style={{color: "#025072", fontWeight: "bold"}} />
          <List style={{padding: "20px 0"}}>
            <Link to="/user">
              <ListItem button>
                <ListItemIcon>
                  <Person style={{color: "#025072"}}/>
                </ListItemIcon>
                <ListItemText primary={"Users"} style={{color: "#025072", fontWeight: "bold"}} />
              </ListItem>
            </Link>
          </List>
        </>
      )}
    </PanelWrapper>
  );
}

export default Aside;
