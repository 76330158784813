import React, { useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { ModalWrapper } from "./dataModal.styled";

export default function DataModal(props) {
  const { open, onClose, children,...restProps } = props;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      {...restProps}
    >
      <Fade in={open}>
        
        <ModalWrapper>{children}</ModalWrapper>
      </Fade>
    </Modal>
  );
}
