import styled from "styled-components";

export const FormWrapper = styled.div`
display: grid;
grid-gap: 20px;
grid-template-columns: 1fr;
width: 500px;
padding: 10px 30px;
margin: 0 auto;

button{
  background: #025072;
}

`;

export const ErrorMessage = styled.p`
  color: var(--red-color);
  text-align: center;
`