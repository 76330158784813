export default class Auth {
  constructor(axios) {
    this.req = axios
    this.route = '/auth'
  }

  async login(obj){
    const {data} = await this.req.post(`${this.route}/login`, obj)
    // if(!data.success) throw new Error(data.error);
    return data
  }

  async refreshToken(){
    const {data} = await this.req.post(`${this.route}/refresh-token`, {})
    // if(!data.success) throw new Error(data.error);
    return data.data
  }

}