import { IconInput } from "../../common/inputs/IconInput";
import { Multiselect } from "../../common/inputs/Multiselect";
import { useState, useEffect } from "react";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import { FormWrapper } from "../form.styled";
import StdButton from "../../common/buttons/StdButton";

import {
  mapAccessLevelsToLocal,
  mapLocalToAccessLevels,
} from "../form.functions";

export const CategoryForm = (props) => {
  const { data, relationsData, isNew, currentUser, onSubmit } = props;
  const [isInitiated, setIsInitiated] = useState(false);
  const [localEntity, setLocalEntity] = useState({
    name: "",
    userCategory: {
      canSee: [],
      canEdit: [],
      canAdd: [],
      isOwner: [],
    },
  });

  const initData = () => {

    if (data && Object.keys(data).length > 0) {
      const entityData = { ...data };
      entityData.userCategory = mapAccessLevelsToLocal(
        entityData.userCategory,
        Object.keys(localEntity.userCategory),
        "userId"
      );
      setLocalEntity({
        ...localEntity,
        ...entityData,
      });
    } else if (isNew) {
      let entityWithOwner = {...localEntity}
      entityWithOwner.userCategory.isOwner.push(currentUser.id)
    }
  };

  const handleDataChange = (ev, key) => {
    const newData = {
      ...localEntity,
      [key]: ev.target.value,
    };
    setLocalEntity(newData);
  };

  const handleUserCategoryDataChange = (ev, key) => {
    const newData = {
      ...localEntity,
    };

    newData.userCategory[key] = ev.target.value;
    setLocalEntity(newData);
  };

  const handleSubmit = (ev) => {
    const dataForParent = { ...localEntity };
    dataForParent.userCategory = mapLocalToAccessLevels(
      dataForParent.userCategory,
      "userId",
      Object.keys(localEntity.userCategory)
    );
    if (onSubmit) onSubmit(dataForParent);
  };

  useEffect(() => {
    initData();
    setIsInitiated(true);
  }, []);

  return (
    <FormWrapper>
      {isInitiated && (
        <>
          <IconInput
            label="Name"
            icon={TextFieldsIcon}
            value={localEntity.name}
            onChange={(ev) => handleDataChange(ev, "name")}
          />
          <Multiselect
            label="Users that can SEE every access in category"
            value={localEntity.userCategory.canSee}
            data={relationsData.user}
            onChange={(ev) => handleUserCategoryDataChange(ev, "canSee")}
            valueKey="id"
            valueLabel="email"
          />

          <Multiselect
            label="Users that can EDIT every access in category"
            value={localEntity.userCategory.canEdit}
            data={relationsData.user}
            onChange={(ev) => handleUserCategoryDataChange(ev, "canEdit")}
            valueKey="id"
            valueLabel="email"
          />

          <Multiselect
            label="Users that can ADD access to this category"
            value={localEntity.userCategory.canAdd}
            data={relationsData.user}
            onChange={(ev) => handleUserCategoryDataChange(ev, "canAdd")}
            valueKey="id"
            valueLabel="email"
          />

          <Multiselect
            label="Category OWNER"
            value={localEntity.userCategory.isOwner}
            data={relationsData.user}
            onChange={(ev) => handleUserCategoryDataChange(ev, "isOwner")}
            valueKey="id"
            valueLabel="email"
          />

          <StdButton onClick={handleSubmit}>Zapisz</StdButton>
        </>
      )}
    </FormWrapper>
  );
};
