import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Chip,
} from "@material-ui/core";
import { useEffect } from "react";

export const Multiselect = (props) => {
  const { className, label, data, valueKey, valueLabel, ...restProps } = props;

  const findLabelForValue = (dataArr, inputValue) => dataArr.find(val => val[valueKey] === inputValue)[valueLabel]
  return (
    <FormControl className={className}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        multiple
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div>
            {selected.map((value) => (
              <Chip key={value} label={findLabelForValue(data, value)} />
            ))}
          </div>
        )}
        {...restProps}
      >
        {data.map((val, i) => (
          <MenuItem key={i} value={val[valueKey]}>
            {val[valueLabel]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Multiselect;
