import { Colors } from "../../colors/Colors";

const { default: styled } = require("styled-components");

export const PanelContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
`;

export const TopBar = styled.div`
padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #025072;
`

export const UsernameSpan = styled.span`
  padding: 5px 0px;
  color: white;
  font-weight: bold;
  background: transparent;
  font-size: 14px;
  border-radius: 999px;
  margin: 0 20px;
`
