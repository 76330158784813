import Repository from './common/Repository'

export default class Access extends Repository {
  constructor(axios) {
    super('/access', axios)
  }


  async listEdit(){
    const {data} = await this.req.get(`${this.route}/edit`)
    return data
  }
}