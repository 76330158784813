import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./main.css";

import PanelView from "./components/panel-view/PanelView";
import LoginView from "./components/login-view/LoginView";
import store from './store/Store'

function Main() {
  const [isLoggedIn, setIsLoggedIn] = useState(store.isLoggedIn());

  useEffect(() => {
    if (isLoggedIn) {
      store.setAxiosToken()
      setIsLoggedIn(true)
    };    
  });

  const logout = () => {
    store.logout()
    setIsLoggedIn(false)
  }

  return (
    <>
      {isLoggedIn ? <PanelView logout={logout}/> : <LoginView setIsLoggedIn={setIsLoggedIn} />}
    </>
  );
}

ReactDOM.render(<Main />, document.getElementById("root"));
