import Access from './repository/Access'
import PersonalAccess from './repository/PersonalAccess'
import Category from './repository/Category'
import User from './repository/User'
import Auth from './repository/Auth'
import UserAccess from './repository/UserAccess'



import axios from './Axios'



class Api {
  constructor(axios) {
    this.access = new Access(axios)
    this.personalAccess = new PersonalAccess(axios)
    this.category = new Category(axios)
    this.user = new User(axios)
    this.auth = new Auth(axios)
    this.userAccess = new UserAccess(axios)
  }
}

export default new Api(axios)