import React from "react";
import { UserForm } from "../../forms/user-form/UserForm";
import PanelContent from "../common/panel-content/PanelContent";

import { EntityTable } from "../../common/tables/entity-table/EntityTable";
function User() {
  return (
    <>
      <PanelContent
        entity="user"
        relations={["user"]}
        columns={["id", "email", "role"]}
        isDeleteable={false}
        form={UserForm}
        dataToShowInDetailedView={["email", "role"]}
        detailsTable={EntityTable}
      ></PanelContent>
    </>
  );
}

export default User;
