import React from "react";
import PanelContent from "../common/panel-content/PanelContent";
import { CategoryForm } from "../../forms/category-form/CategoryForm";
import { EntityTable } from "../../common/tables/entity-table/EntityTable";

function Category() {
  return (
    <>
      <PanelContent
        entity="category"
        relations={["user"]}
        columns={["id", "name"]}
        isDeleteable={true}
        form={CategoryForm}
        dataToShowInDetailedView={["id", "name"]}
        detailsTable={EntityTable}
      ></PanelContent>
    </>
  );
}

export default Category;
