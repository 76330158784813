import { useEffect, useState } from "react";
import api from "../../../api/Api";
import { ErrorMessage } from "./table.styled";

export const DataTable = (props) => {
  const Table = props.detailsTable;
  const { entity, entityId } = props;

  const [errors, setErrors] = useState([]);
  const [data, setData] = useState(null);

  useEffect(async () => {
    let entityData = null;
    try {
      entityData = await api[entity].get(entityId);
    } catch (err) {
      if (err.response.status == 403)
        setErrors(["You have no access to see this data"]);
    }
    setData(entityData);
  }, []);

  return (
    <>
      {" "}
      {errors &&
        errors.length > 0 &&
        errors.map((error, i) => (
          <ErrorMessage key={i}>{error}</ErrorMessage>
        ))}{" "}
      {data && <Table data={data} {...props} />}
    </>
  );
};
