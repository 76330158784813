import { IconInput } from "../../common/inputs/IconInput";
import { Multiselect } from "../../common/inputs/Multiselect";
import { ObjectSelect } from "../../common/inputs/ObjectSelect";
import { useState, useEffect } from "react";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import { FormWrapper } from "../form.styled";
import StdButton from "../../common/buttons/StdButton";

import {
  mapAccessLevelsToLocal,
  mapLocalToAccessLevels,
} from "../form.functions";

export const AccessForm = (props) => {
  const { data, relationsData, isNew, currentUser, onSubmit } = props;
  const [isInitiated, setIsInitiated] = useState(false);
  const [localEntity, setLocalEntity] = useState({
    name: "",
    login: "",
    link: "",
    password: "",
    description: "",
    category: "",
    userAccess: {
      canSee: [],
      canEdit: [],
      isOwner: [],
    },
  });

  const initData = () => {
    if (data && Object.keys(data).length > 0) {
      const entityData = { ...data };
      const {
        name,
        login,
        link,
        password,
        description,
      } = entityData.baseAccess;
      const category = entityData.category.id;
      entityData.userAccess = mapAccessLevelsToLocal(
        entityData.userAccess,
        Object.keys(localEntity.userAccess),
        "userId"
      );
      setLocalEntity({
        ...localEntity,
        ...entityData,
        name,
        login,
        link,
        password,
        description,
        category,
      });
    } else if (isNew) {
      let entityWithOwner = {...localEntity}
      entityWithOwner.userAccess.isOwner.push(currentUser.id)
    }
  };

  const handleDataChange = (ev, key) => {
    const newData = {
      ...localEntity,
      [key]: ev.target.value,
    };
    setLocalEntity(newData);
  };

  const handleUserAccessDataChange = (ev, key) => {
    const newData = {
      ...localEntity,
    };

    newData.userAccess[key] = ev.target.value;
    setLocalEntity(newData);
  };

  const handleSubmit = (ev) => {
    const dataForParent = { ...localEntity };
    dataForParent.userAccess = mapLocalToAccessLevels(
      dataForParent.userAccess,
      "userId",
      Object.keys(localEntity.userAccess)
    );

    const { name, login, link, password, description } = dataForParent;
    dataForParent.baseAccess = { name, login, link, password, description };

    if (onSubmit) onSubmit(dataForParent);
  };

  useEffect(() => {
    initData();
    setIsInitiated(true);
  }, []);

  return (
    <FormWrapper>
      {isInitiated && (
        <>
          <IconInput
            label="Name"
            icon={TextFieldsIcon}
            value={localEntity.name}
            onChange={(ev) => handleDataChange(ev, "name")}
          />
          <ObjectSelect
            label="Category"
            data={relationsData.category}
            onChange={(ev) => handleDataChange(ev, "category")}
            value={localEntity.category}
            valueKey="id"
            valueLabel="name"
          />
          <IconInput
            label="Login"
            icon={TextFieldsIcon}
            value={localEntity.login}
            onChange={(ev) => handleDataChange(ev, "login")}
          />
          <IconInput
            label="Password"
            icon={TextFieldsIcon}
            value={localEntity.password}
            onChange={(ev) => handleDataChange(ev, "password")}
          />
          <IconInput
            label="Link"
            icon={TextFieldsIcon}
            value={localEntity.link}
            onChange={(ev) => handleDataChange(ev, "link")}
          />
          <IconInput
            label="Description"
            icon={TextFieldsIcon}
            value={localEntity.description}
            onChange={(ev) => handleDataChange(ev, "description")}
          />
          <Multiselect
            label="Users that can SEE access"
            value={localEntity.userAccess.canSee}
            data={relationsData.user}
            onChange={(ev) => handleUserAccessDataChange(ev, "canSee")}
            valueKey="id"
            valueLabel="email"
          />

          <Multiselect
            label="Users that can EDIT access"
            value={localEntity.userAccess.canEdit}
            data={relationsData.user}
            onChange={(ev) => handleUserAccessDataChange(ev, "canEdit")}
            valueKey="id"
            valueLabel="email"
          />

          <Multiselect
            label="Access OWNER"
            value={localEntity.userAccess.isOwner}
            data={relationsData.user}
            onChange={(ev) => handleUserAccessDataChange(ev, "isOwner")}
            valueKey="id"
            valueLabel="email"
          />

          <StdButton onClick={handleSubmit}>Zapisz</StdButton>
        </>
      )}
    </FormWrapper>
  );
};
