import { useEffect, useRef, useState } from "react";
import api from "../../api/Api";
import store from "../../store/Store";
import { ErrorMessage } from "./form.styled";

export const DataForm = (props) => {
  const Form = props.form;
  const { onSubmit, entity, entityId, relations } = { ...props };
  const method = entityId ? "update" : "create";
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState([]);
  const [relationsData, setRelationsData] = useState(null);

  const handleSumbit = async (newData) => {
    let isError = false;
    try {
      const res = await api[entity][method](newData);
      await store.reloadData(entity);
    } catch (err) {
      isError = true;
      if (err.response.status == 403)
        setErrors(["You have no access to edit this data"]);
      else setErrors(err.response.data.message);
    }
    if (onSubmit && !isError) onSubmit();
  };

  const handleDataChange = (newData) => {
    setData((prevState) => {
      return {
        ...prevState,
        ...newData,
      };
    });
  };

  const isEverythingLoaded = () => {
    return (
      !!relationsData === !!(relations && relations.length) &&
      !!data === !!entityId
    );
  };

  useEffect(async () => {
    if (entityId) {
      let entityData = null;
      try {
        entityData = await api[entity].get(entityId);
      } catch (err) {
        if (err.response.status == 403)
          setErrors(["You have no access to see this data"]);
      }
      setData(entityData)
    }

    if (relations) {
      setRelationsData(await store.getDataForEntities(relations));
    }
  }, []);

  return (
    <>
      {errors &&
        errors.length > 0 &&
        errors.map((error, i) => <ErrorMessage key={i}>{error}</ErrorMessage>)}
      {isEverythingLoaded() && (
        <Form
          data={data}
          onSubmit={handleSumbit}
          onDataChange={handleDataChange}
          currentUser={store.getCurrentUser()}
          isNew={!entityId}
          relationsData={relationsData}
        />
      )}
    </>
  );
};
