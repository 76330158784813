import StdButton from "../common/buttons/StdButton";
import IconInput from "../common/inputs/IconInput";

const { default: styled } = require("styled-components");

export const LoginContainter = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30vw;
  min-width: 390px;
  height: 60vh;
  min-height: 430px;
  padding: 10px 20px;
  border: 1px solid #025072;
  border-radius: 10px;
  text-align: center;

  header {
    padding-top: 30px;
  }

  header h1 {
    font-size: 40px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
`;

export const LoginInput = styled(IconInput)`
  margin-bottom: 20px;
  width: 250px;
`;

export const LoginButton = styled(StdButton)`
  width: 70%;
  background-color: #025072;
`;
