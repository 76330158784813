import { IconInput } from "../../common/inputs/IconInput";
import { ObjectSelect } from "../../common/inputs/ObjectSelect";
import { useState, useEffect } from "react";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import { FormWrapper } from "../form.styled";
import StdButton from "../../common/buttons/StdButton";

import {
  mapAccessLevelsToLocal,
  mapLocalToAccessLevels,
} from "../form.functions";
import store from "../../../store/Store";

export const UserForm = (props) => {
  const { data, relationsData, isNew, currentUser, onSubmit } = props;
  const [isInitiated, setIsInitiated] = useState(false);
  const [localEntity, setLocalEntity] = useState({
    email: "",
    password: "",
    role: "",
    userAccess: [],
    userCategory: []
  });

  const initData = () => {
    if (data && Object.keys(data).length > 0) {
      const entityData = {...data}
      setLocalEntity({
        ...localEntity,
        ...entityData,
      });
    }
  };

  const handleDataChange = (ev, key) => {
    const newData = {
      ...localEntity,
      [key]: ev.target.value,
    };
    setLocalEntity(newData);
  };

  const handleSubmit = (ev) => {
    const dataForParent = { ...localEntity };
    if (onSubmit) onSubmit(dataForParent);
  };

  useEffect(() => {
    initData();
    setIsInitiated(true);
  }, []);

  return (
    <FormWrapper>
      {isInitiated && (
        <>
          <IconInput
            label="Email"
            icon={TextFieldsIcon}
            value={localEntity.email}
            onChange={(ev) => handleDataChange(ev, "email")}
          />
          <IconInput
            label="Password"
            icon={TextFieldsIcon}
            value={localEntity.login}
            onChange={(ev) => handleDataChange(ev, "password")}
          />
          <ObjectSelect
            label="Role"
            data={store.getRoles()}
            onChange={(ev) => handleDataChange(ev, "role")}
            value={localEntity.role}
            valueKey="value"
            valueLabel="role"
          />
          <StdButton onClick={handleSubmit}>Zapisz</StdButton>
        </>
      )}
    </FormWrapper>
  );
};
