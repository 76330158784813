import { IconButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

export const ShowButton = (props) => {
  return (
    <IconButton {...props} color="primary" component="span">
      <VisibilityIcon />
    </IconButton>
  );
};
