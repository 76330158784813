import styled from "styled-components";

export const PanelContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  min-height: 93vh;
`;

export const TopButtonsWrapper = styled.div`
  position: sticky;
  align-self: right;
  bottom: 50px;
  left: 93vw;
  background: #025072;
  border-radius: 50%;
`;

export const GroupWrapper = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
`;
export const Group = styled.div`
  color: var(--black-color);
  padding: 5px 20px;
  border-radius: 999px;
  background: #ddd;
  transition: all 0.3s ease;
  margin: 0 5px;

  &.active,
  &:hover {
    background: #aaa;
  }

  &:hover {
      cursor: pointer;
  }
`;

export const Card = styled.div`
 box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  padding: 10px;
  width: 400px;
  height: fit-content;
  background: #CADEED;
  border-radius: 10px;
  border: 3px solid white;
`

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

`

export const FlexCenter = styled.div``
