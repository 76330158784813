import { IconInput } from "../../common/inputs/IconInput";
import { Multiselect } from "../../common/inputs/Multiselect";
import { useState, useEffect } from "react";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import { FormWrapper } from "../form.styled";
import StdButton from "../../common/buttons/StdButton";

import {
  mapAccessLevelsToLocal,
  mapLocalToAccessLevels,
} from "../form.functions";

export const PersonalAccessForm = (props) => {
  const { data, relationsData, isNew, currentUser, onSubmit } = props;
  const [isInitiated, setIsInitiated] = useState(false);
  const [localEntity, setLocalEntity] = useState({
    name: "",
    login: "",
    link: "",
    password: "",
    description: "",
  });

  const initData = () => {
    if (data && Object.keys(data).length > 0) {
      const entityData = { ...data };
      const {
        name,
        login,
        link,
        password,
        description,
      } = entityData.baseAccess;
      setLocalEntity({
        ...localEntity,
        ...entityData,
        name,
        login,
        link,
        password,
        description,
      });
    }
  };

  const handleDataChange = (ev, key) => {
    const newData = {
      ...localEntity,
      [key]: ev.target.value,
    };
    setLocalEntity(newData);
  };

  const handleSubmit = (ev) => {
    const dataForParent = { ...localEntity };
    const { name, login, link, password, description } = dataForParent;
    dataForParent.baseAccess = { name, login, link, password, description };
    if (onSubmit) onSubmit(dataForParent);
  };

  useEffect(() => {
    initData();
    setIsInitiated(true);
  }, []);

  return (
    <FormWrapper>
      {isInitiated && (
        <>
          <IconInput
            label="Name"
            icon={TextFieldsIcon}
            value={localEntity.name}
            onChange={(ev) => handleDataChange(ev, "name")}
          />
          <IconInput
            label="Login"
            icon={TextFieldsIcon}
            value={localEntity.login}
            onChange={(ev) => handleDataChange(ev, "login")}
          />
          <IconInput
            label="Password"
            icon={TextFieldsIcon}
            value={localEntity.password}
            onChange={(ev) => handleDataChange(ev, "password")}
          />
          <IconInput
            label="Link"
            icon={TextFieldsIcon}
            value={localEntity.link}
            onChange={(ev) => handleDataChange(ev, "link")}
          />
          <IconInput
            label="Description"
            icon={TextFieldsIcon}
            value={localEntity.description}
            onChange={(ev) => handleDataChange(ev, "description")}
          />
          <StdButton onClick={handleSubmit}>Zapisz</StdButton>
        </>
      )}
    </FormWrapper>
  );
};
