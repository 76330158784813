import React, { useEffect, useState } from "react";
import EmailIcon from "@material-ui/icons/Email";
import "./loginView.styled.js";
import { VpnKey } from "@material-ui/icons";
import { LoginButton, LoginContainter, LoginInput } from "./loginView.styled";

import api from "../../api/Api";
import store from "../../store/Store";

function LoginView({ setIsLoggedIn }) {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState([]);

  const handleChange = (value, key) => {
    const newCredentials = {
      ...credentials,
    };
    newCredentials[key] = value;
    setCredentials(newCredentials);
  };

  const handleLogin = async (ev) => {
    ev.preventDefault();
    try {
    const {token} = await api.auth.login(credentials);
    store.login(token)
    setIsLoggedIn(true);
    } catch (err) {
      if (err.response.status === 400) setErrors(["Invalid credentials"]);
    }
  };

  return (
    <>
      <LoginContainter>
        <header>
          <h1>Access</h1>
        </header>
        <form className="login-form" method="post" onSubmit={handleLogin}>
          {
            errors && errors.length > 0 && errors.map(err => <p className="red">{err}</p>)
          }
          <LoginInput
            value={credentials.email}
            onChange={(ev) => handleChange(ev.target.value, "email")}
            icon={EmailIcon}
            label="Email"
          />
          <LoginInput
            value={credentials.password}
            onChange={(ev) => handleChange(ev.target.value, "password")}
            icon={VpnKey}
            label="Password"
            type="password"
          />
          <LoginButton
            variant="contained"
            className="std-btn login-btn"
            type="submit"
            style={{marginTop: "20px", background: "#025072"}}
          >
            Login
          </LoginButton>
        </form>
      </LoginContainter>
    </>
  );
}

export default LoginView;
