import { Grid, TextField } from "@material-ui/core";
import { useEffect } from "react";
import styled from "styled-components";

const InputContainer = styled.div`
  .input-main {
    width: calc(100% - 40px);
    .MuiFormControl-root {
      width: 100%;
    }
  }

  .input-icon {
    color: var(--icon-color);

  }
`;

export const IconInput = function (props) {
  const Icon = props.icon;
  const { className, ...restProps } = props;

  return (
    <InputContainer className={className}>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item>
          <Icon className="input-icon"/>
        </Grid>
        <Grid item className="input-main">
          <TextField id="input-with-icon-grid" {...restProps} />
        </Grid>
      </Grid>
    </InputContainer>
  );
};

export default IconInput;
