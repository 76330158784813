import { Divider } from "@material-ui/core";
import styled from "styled-components";

export const PanelWrapper = styled.div`
  padding-left: 15px;
  padding-top: 15px;
  width: 300px;
  background: rgb(217, 239, 255);
  box-shadow: 0 0px 8px 0 rgb(0 0 0 / 20%);

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const TitleDivider = (props) => {
  const TitleSpan = styled.span`
    font-size: 0.9rem;
    color: var(--gray-color);
  `;
  return (
    <>
      <TitleSpan>{props.dividerTitle}</TitleSpan>
      <Divider />
    </>
  );
};
