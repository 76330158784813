import axios from "../api/Axios";
import api from "../api/Api";

const CACHE_TIME = 0 * 6e4; // 3 minutes
const ROLES = [
  {
    role: "user",
    value: 1,
  },
  {
    role: "manager",
    value: 2,
  },
  {
    role: "admin",
    value: 3,
  },
];

export class Store {
  constructor(ls, axios, api) {
    this.ls = ls;
    this.axios = axios;
    this.api = api;
    this.timeouts = {};
  }

  get(name) {
    return JSON.parse(this.ls.getItem(name)) || null;
  }

  set(name, value) {
    this.ls.setItem(name, JSON.stringify(value));
  }

  remove(name) {
    this.ls.removeItem(name);
  }

  setAxiosToken() {
    this.axios.defaults.headers.common["Authorization"] = `Bearer ${this.get(
      "jwt"
    )}`;
  }

  removeAxiosToken() {
    this.axios.defaults.headers.common["Authorization"] = null;
  }

  login(token) {
    this.set("jwt", token);
    this.set("currentUser", this.decodeJwt(token));
    this.setAxiosToken();
    this.tokenChecker();
  }

  clearData() {
    this.remove("jwt");
    this.remove("currentUser");
    this.remove("user");
    this.remove("access");
    this.remove("accessEdit");
    this.remove("personalAccess");
    this.remove("category");
  }

  logout() {
    this.clearData();
    this.removeAxiosToken();
    clearTimeout(this.timeouts["tokenChecker"]);
  }

  getCurrentUser() {
    return this.get("currentUser");
  }

  getUsername() {
    return this.getCurrentUser().email
  }

  // async refreshToken() {
  //   const {
  //     token
  //   } = await this.api.auth.refreshToken()
  //   this.login(token)
  // }

  tokenChecker() {
    const toExpire = this.getTimeToExpire();
    this.timeouts["tokenChecker"] = setTimeout(async () => {
      await this.refreshToken();
    }, toExpire - 1000 * 60);
  }

  getTimeToExpire() {
    const token = this.get("jwt");
    if (!token) return 0;
    const { exp } = this.decodeJwt(token);
    const now = new Date().getTime();
    return exp * 1000 - now;
  }

  isLoggedIn() {
    const jwt = this.get("jwt");
    if (!jwt) return false;
    if (this.getTimeToExpire(jwt) <= 0) return false;
    return true;
  }


  hasRoleOrBetter(role) {
    return this.getCurrentUser().role >= ROLES.find(rl => rl.role === role).value
  }
  
  decodeJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }


  async getEditAccessIds(name) {
    const data = await this.getData(`${name}Edit`);
    return data.map(d => d.id)
  }

  async reloadData(name) {
    let data = []
    if(name === "accessEdit") {
      data = await this.api.access.listEdit()
    } else {
      data = await this.api[name].list()
    }
    this.set(name, data);
    this.setReloadDate(name);
  }

  setReloadDate(name) {
    let reloadDates = this.get("reloadDates");
    if (!reloadDates) reloadDates = {};
    reloadDates[name] = new Date();
    this.set(`reloadDates`, reloadDates);
  }

  isReloadNeeded(name, cacheTime) {
    const reloadDates = this.get(`reloadDates`);
    const repo = this.get(name);

    if (repo && reloadDates && reloadDates[name]) {
      const lastReload = new Date(reloadDates[name]);
      const now = new Date();
      return now - lastReload > cacheTime;
    }
    return true;
  }

  async getData(name, isForceReload = false) {
    if (isForceReload || this.isReloadNeeded(name, CACHE_TIME))
      await this.reloadData(name);
    return this.get(name) || [];
  }

  async getDataForEntities(list) {
    const promises = [];
    const data = {};
    list.forEach((entity) => {
      const promisedData = this.getData(entity);
      promises.push(promisedData);
    });
    const dataArr = await Promise.all(promises);
    list.forEach((entity, i) => {
      data[entity] = dataArr[i];
    });

    return data;
  }

  getRoles() {
    return [...ROLES];
  }
}

export default new Store(localStorage, axios, api);
