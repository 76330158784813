export const mapAccessLevelsToLocal = (
  accessLevels,
  possibleAccessLevelKeys,
  identityKey
) => {
  const tempAccessLevels = {};
  accessLevels.map((uc) => {
    possibleAccessLevelKeys.forEach((accLvlKey) => {
      if (!tempAccessLevels[accLvlKey]) tempAccessLevels[accLvlKey] = [];

      if (uc[accLvlKey]) {
        tempAccessLevels[accLvlKey].push(uc[identityKey]);
      }
    });
  });
  return tempAccessLevels;
};

export const mapLocalToAccessLevels = (
  local,
  idField,
  possibleAccessLevelKeys
) => {
  const idsArray = [];
  possibleAccessLevelKeys.forEach((lvlKey) => idsArray.push(...local[lvlKey]));
  const uniqueIds = [...new Set(idsArray)];
  const accessLevels = [];
  uniqueIds.forEach((id) => {
    const object = {};
    object[idField] = id;
    possibleAccessLevelKeys.forEach(
      (lvlKey) => (object[lvlKey] = local[lvlKey].includes(id))
    );
    accessLevels.push(object);
  });
  return accessLevels;
};
