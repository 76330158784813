import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { PanelContainer, TopBar, UsernameSpan } from "./panelView.styled.js";
import Aside from "./aside/Aside";
import PersonalAccess from "./personal-access/PersonalAccess";
import Access from "./access/Access";
import Category from "./category/Category";
import User from "./user/User";
import PanelContent from "./common/panel-content/PanelContent";
import StdButton from "../common/buttons/StdButton.js";
import store from "../../store/Store.js";
import UserAccess from "./user-access/UserAccess.js";
import styled from "styled-components";
import { Person, PersonPinCircle } from "@material-ui/icons";

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconBox = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

function PanelView(props) {
  const { logout } = props;
  return (
    <>
      <TopBar>
        <div style={{ color: "white", paddingLeft: "50px" }}>
          <h1>Access</h1>
        </div>
        <Flex>
          <IconBox>
            <Person style={{fontSize: "38px", color: "grey", marginTop: "10px"}} />
          </IconBox>
          <UsernameSpan>{store.getUsername()}</UsernameSpan>
          <StdButton onClick={logout}>Logout</StdButton>
        </Flex>
      </TopBar>

      <PanelContainer>
        <BrowserRouter>
          <Aside />
          <Switch>
            <Route path="/" exact>
              <Access />
            </Route>
            <Route path="/access">
              <Access />
            </Route>
            <Route path="/personalAccess">
              <PersonalAccess />
            </Route>
            <Route path="/userAccess">
              <UserAccess />
            </Route>
            <Route path="/category">
              <Category />
            </Route>
            <Route path="/user">
              <User />
            </Route>
          </Switch>
        </BrowserRouter>
      </PanelContainer>
    </>
  );
}

export default PanelView;
